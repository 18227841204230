import { useState } from 'react';
import Bar from '../template/sidebar';
import { Helmet } from 'react-helmet';

export default function Server(){
    const [Server,SetServer] = useState('All Server')
    const [Input_UserK,Set_Input_UserK] = useState('')
    const [IsSearchUser,SetISSearchUser] = useState(false)
    const [Input_UserB,Set_Input_UserB] = useState('')
    const [IsSearchUserBan,SetISSearchUserBan] = useState(false)

    const [TargetKick,Set_TargetKick] = useState({})
    const [TargetBan,Set_TargetBan] = useState({})
    const OptionChange = (e) => {
        SetServer(e.target.value)
    }
    const Input_UserChange_K = (e) => {
        Set_Input_UserK(e.target.value)
    }
    const SetValueBanInput = (e) => {
        Set_Input_UserB(e.target.value)
    }
    async function Shutdown(){
        let ServerType = Server.replace('Server','')
        ServerType = ServerType.replace(' ','')
        try{
            const Response = await fetch('https://api.yeke-hub.com/Shutdown',{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'Type': ServerType
                })
            })
            if (Response.ok){
                console.log(await Response.json())
            }
        }catch(err){
            throw err
        }
    }
    async function Kick(){
        if (IsSearchUser == false){
            try {
                const Response = await fetch(`https://api.yeke-hub.com/UserInfo?User=${Input_UserK}`,{
                    method: 'GET',
                    headers: {
                        'accept': 'application/json'
                    }

                })
                if (Response.ok){
                    Set_TargetKick(await Response.json())
                    SetISSearchUser(true)
                }
            }catch(err){
                throw err
            }
        }else{
            if (IsSearchUser == true && TargetKick != {}){
                SetISSearchUser(false)
                Set_Input_UserK('')
                try {
                    const Response = await fetch(`https://api.yeke-hub.com/KickUser`,{
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            User: TargetKick.id
                        })
    
                    })
                    if (Response.ok){
                        console.log(await Response.json())
                        Set_TargetKick({})
                    }
                }catch(err){
                    throw err
                }
            }
        }
    }

    async function Ban(){
        if (IsSearchUserBan == false){
            try {
                const Response = await fetch(`https://api.yeke-hub.com/UserInfo?User=${Input_UserB}`,{
                    method: 'GET',
                    headers: {
                        'accept': 'application/json'
                    }

                })
                if (Response.ok){
                    Set_TargetBan(await Response.json())
                    SetISSearchUserBan(true)
                }
            }catch(err){
                throw err
            }
        }else{
            if (IsSearchUserBan == true && TargetBan != {}){
                SetISSearchUserBan(false)
                Set_Input_UserB('')
                try {
                    const Response = await fetch(`https://api.yeke-hub.com/BanUser`,{
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            User: TargetBan.id
                        })
    
                    })
                    if (Response.ok){
                        console.log(await Response.json())
                        Set_TargetBan({})
                    }
                }catch(err){
                    throw err
                }
            }
        }
    }
    return (
        <div className="flex w-screen h-screen">
            <Helmet>
                <title>Server</title>
            </Helmet>
            <Bar/>
            <div id="Main" className="flex-1 flex flex-col items-center bg-gray-200 w-full min-h-screen ml-56 overflow-x-hidden overflow-y-auto">
                <h1 className='font-bold text-3xl ml-4 mt-4 mb-16 mr-auto'>Server Management</h1>
                <div id="Kick" className='flex flex-col items-center bg-gray-100 w-[95%]  mt-10 rounded-lg'>
                    <div className='w-full border-b-2'>
                        <h1 className='font-bold text-2xl mr-auto ml-3 mt-2 mb-3'>Kick User From Server</h1>
                    </div>
                    <div className='flex items-center mt-10 mb-10'>
                        <input type='text' className='w-40 h-7 text-left font-semibold border-gray-500 border-2 rounded-md outline-none mr-5' placeholder='Enter Userid or Username' value={Input_UserK} onChange={Input_UserChange_K}/>
                        <button className="w-24 h-8 bg-red-700 rounded font-bold text-white mr-5"  onClick={Kick}>{IsSearchUser? "Kick":"Search"}</button>
                        {IsSearchUser && <div className='flex items-center rounded-lg border-2 border-gray-300'>
                            <img className='h-10 w-10 border-2 rounded-full mr-3' src={TargetKick.imageUrl}/>
                            <div className='flex flex-col flex-grow items-center mr-3'>
                                <h1 className='font-semibold'>{TargetKick.name}</h1>
                                <p className='font-semibold'>{TargetKick.id}</p>
                            </div>
                        </div> }               
                    </div>
                </div>
                <div id="Ban" className='flex flex-col items-center bg-gray-100 w-[95%] h-36  mt-10 rounded-lg'>
                    <div className='w-full border-b-2'>
                        <h1 className='font-bold text-2xl mr-auto ml-3 mt-2 mb-3'>Ban User From Server</h1>
                    </div>
                    <div className='flex items-center mt-10 mb-10'>
                        <input type='text' className='w-40 h-7 text-left font-semibold border-gray-500 border-2 rounded-md outline-none mr-5' placeholder='Enter Userid or Username' value={Input_UserB} onChange={SetValueBanInput}/>
                        <button className="w-24 h-8 bg-red-700 rounded font-bold text-white mr-5"  onClick={Ban}>{IsSearchUserBan? "Ban":"Search"}</button>
                        {IsSearchUserBan && <div className='flex items-center rounded-lg border-2 border-gray-300'>
                            <img className='h-10 w-10 border-2 rounded-full mr-3' src={TargetBan.imageUrl}/> 
                            <div className='flex flex-col flex-grow items-center mr-3'>
                                <h1 className='font-semibold'>{TargetBan.name}</h1>
                                <p className='font-semibold'>{TargetBan.id}</p>
                            </div>
                        </div> }               
                    </div>
                </div>
                <div id="Shutdown" className='flex flex-col items-center  bg-gray-100 w-[95%] h-36  mt-10 rounded-lg'>
                    <div className='w-full border-b-2'>
                        <h1 className='font-bold text-2xl mr-auto ml-3 mt-2 mb-3'>Shutdown Server</h1>
                    </div>
                    <div className='mt-5'>
                        <select className='border-gray-500 border-2 rounded-md  outline-none font-semibold mr-10' onChange={OptionChange}>
                            <option className='font-semibold'>All Server</option>
                            <option className='font-semibold'>Public Server</option>
                            <option className='font-semibold'>Private Server</option>
                        </select>
                        <button className="w-24 h-8 bg-red-700 rounded font-bold text-white" onClick={Shutdown} >Shutdown</button>                    
                    </div>
                </div>
            </div>
        </div>
    )
}