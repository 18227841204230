import { useState } from 'react';
import Bar from '../template/sidebar';
import { Helmet } from 'react-helmet';
export default function Message(){
    const [message,Setmessage] = useState('')
    const [Server,SetServer] = useState('All Server')
    
    const TextChange = (v) => {
        Setmessage(v.target.value)
    }
    const OptionChange = (e) => {
        SetServer(e.target.value)
    }
    async function Send_Message(){
        const TextSizeByte = (str) => new Blob([str]).size
        const User = JSON.parse(localStorage.getItem('user'))
        let ServerType = 'A'
        console.log(Server)
        switch (Server) {
            case "All Server":
                    ServerType = 'A'
                break;
            case "Public Server":
                    ServerType = 'P'
                break;
            case "Private Server":
                    ServerType = 'PS'
                break;
            default:
                    ServerType = 'A'
                break;
        }
        const Arg_message = `${User.preferred_username} ${ServerType} ${message}`
        if (TextSizeByte(Arg_message) < 1000){
            const Req = await fetch('https://api.yeke-hub.com/MessageServer',{
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'Topic': 'Message-Cross-Server',
                    'message': Arg_message
                })
            })
            console.log(await Req.json())
        }else{
            alert('Your Message More than 1Kb')
        }
    }
    return (
        <div className="flex w-screen h-screen">
            <Helmet>
                <title>Message</title>
            </Helmet>
            <Bar/>
            <div id="Main" className="flex-1 flex flex-col items-center bg-gray-200 w-full min-h-screen ml-56 overflow-x-hidden overflow-y-auto">
                <h1 className='font-bold text-3xl ml-4 mt-4 mb-16 mr-auto'>Message</h1>
                <div id="SendToServer" className='flex flex-col items-center  bg-gray-100 w-[95%] h-56 mt-10 rounded-lg'>
                    <h1 className="font-bold text-2xl mb-5 ml-3 mt-3 mr-auto">Send To Server</h1>
                    <div className='flex justify-center w-full mb-5'>
                        <textarea placeholder='Enter Your Message' type='text' rows={3} value={message} onChange={TextChange} className='w-[95%] h-36 text-left font-semibold border-gray-500 border-2 rounded-md  outline-none resize-none placeholder:text-center placeholder:font-semibold'/>
                    </div>
                    <div className='flex justify-center '>
                        <select className='border-gray-500 border-2 rounded-md  outline-none font-semibold mr-10' onChange={OptionChange}>
                            <option className='font-semibold'>All Server</option>
                            <option className='font-semibold'>Public Server</option>
                            <option className='font-semibold'>Private Server</option>
                        </select>
                        <button className="w-24 h-8 bg-green-700 rounded font-bold text-white"  onClick={Send_Message}>Send</button>                    
                    </div>
                </div>
            </div>
        </div>
    )
}