
function Login(){

    async function OAuth(e){
        e.preventDefault()

        try{
            const Response = await fetch('https://api.yeke-hub.com/Login',{
                method: "GET",
                credentials: 'include',


            })

            console.log("s" + Response.headers.get('Set-Cookie'))
            console.log(document.cookie.split(';'))
            const JSON_Data = await Response.json()
            if (Response.ok){
                window.location.href =  JSON_Data.Oauth_Url
            }else{
    
                console.log(Response)
            }
        }catch(err){
            console.log(err)
        }
    }
    return (
        <div className="w-full h-full flex justify-center items-center">
            <form onSubmit={OAuth} className="mt-36">
                <button type="submit">Login</button>
            </form>
        </div>
    )
}


export default Login;

