import { useState } from "react";
import Bar from '../template/sidebar';
import { Helmet } from "react-helmet";

function Code(){

    const [Date_Input, SetDate] = useState('')
    const [Code,SetCode] = useState('')
    
    async function Save(e){
        e.preventDefault()
        
        if (Date_Input != ''){
            //Convert to Utc
            let UTC = new Date(Date_Input)
            UTC.toUTCString()
            UTC.toISOString()

            //Convert to Timestamp
            const Timestamp = Math.floor(UTC/1000) + (3600 * 8) //Set To GMT +8 For Asia/Kula_Lumpur Timezone

            //Start Make Request to Backend Server
            try {
                const response = await fetch('https://api.yeke-hub.com/Code', {
                    method: "POST",
                    headers: {
                        "content-type": "application/json",
                    },
                    body: JSON.stringify({
                        "Code": Code,
                        "Expired": Timestamp
                    })
                })
            
                console.log(response)

            }catch(Err){

            }
        }
    }

    return (
        <div className="flex w-screen h-screen">
            <Helmet>
                <title>Code</title>
            </Helmet>
            <Bar/>
            <div id="Main" className="flex-1 flex flex-col items-center bg-gray-200 w-full min-h-screen ml-56 overflow-x-hidden overflow-y-auto">
                <h1 className='font-bold text-3xl ml-4 mt-4 mb-16 mr-auto'>Code</h1>
                <form className="flex flex-col justify-center w-[90%] bg-gray-100 rounded-lg p-5" onSubmit={Save}>
                    <label className="font-bold text-2xl mb-5">New Code</label>
                    <div className="flex flex-wrap w-full mb-5">
                        <input type='text' placeholder="Enter Code" className="mt-5 focus:outline-none rounded border-gray-400 placeholder:pl-3 placeholder:font-semibold focus:border-blue-500 border-2 flex-grow mr-5" value={Code} onInput={e => SetCode(e.target.value)}/>
                        <input type="datetime-local" className="mt-5 focus:outline-none rounded border-gray-400 placeholder:font-semibold focus:border-blue-500 border-2 flex-grow" value={Date_Input} onInput={e => SetDate(e.target.value)}/>
                    </div>
                    <div className="flex justify-center w-full mt-6" id="Center-Button">
                        <button className="w-24 h-8 bg-green-700 rounded font-bold text-white" type="submit">Save</button>                    
                    </div>
                </form>
            </div>
        </div>
    )
}
export default Code;