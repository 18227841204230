import { Link, json, useLocation } from "react-router-dom"
import { CiHome } from "react-icons/ci";
import { MdSpaceDashboard } from "react-icons/md";
import { IoMdAddCircle } from "react-icons/io";
import { FaMessage } from "react-icons/fa6";
import { FaServer } from "react-icons/fa";
import { useState, useEffect } from "react";
import Cookies from 'js-cookie';

function DecodeCookie(CookieToken,Part){
    if (CookieToken && CookieToken.startsWith('s:')) {
        CookieToken = CookieToken.slice(2);
    }
    const tokenParts = CookieToken.split('.');
    if (tokenParts.length > Part) {
        CookieToken = tokenParts.slice(0, Part).join('.');
    }

    return CookieToken
}
export default function Bar(){

    function CheckPage(Page){
        if (Page == useLocation().pathname){
            return "bg-cyan-300 text-gray-200"
        }else{
            return "bg-white text-black"
        }
    }

    async function GetUserData(Token){
        const userResponse = await fetch('https://apis.roblox.com/oauth/v1/userinfo', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${Token}`
            }
        });
        if (userResponse.ok){
            return [true,await userResponse.json()]
        }
        return [false, null]
    }

    const [access_token,setAccessToken] = useState('')
    const [username,setUsername] = useState('ye._.ke')
    const [Profile,setProfile] = useState('')
    useEffect(() => {
        const fetchUserData = async () => {
            let jwt = Cookies.get('access_token');
            let RefereshToken = Cookies.get('refresh_token')
            if (jwt){
                jwt = DecodeCookie(jwt,3)
                const [Response, Data] = await GetUserData(jwt)
                console.log(Response)
                if (Response == false ){
                    if (RefereshToken) {
                        RefereshToken = DecodeCookie(RefereshToken,5)
                        const Encrypt = {
                            grant_type: 'refresh_token',
                            refresh_token: RefereshToken,
                            client_id: process.env.REACT_APP_CLIENT_ID,
                            client_secret: process.env.REACT_APP_SECRET
                        }
        
                        const NewAccsessToken = await fetch('https://apis.roblox.com/oauth/v1/token', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                            body: new URLSearchParams(Encrypt).toString()
                        })
                        if (NewAccsessToken.ok){
                            const cookie_Config = {
                                secure: true,
                                sameSite: 'None',
                                domain: '.yeke-hub.com',
                                expires: 7 // expires in 7 days
                            };
                            const Response_New_Token = await NewAccsessToken.json()
                            Cookies.set('access_token',Response_New_Token.access_token,cookie_Config)
                            Cookies.set('refresh_token',Response_New_Token.refresh_token,cookie_Config)
                            Cookies.set('token_type',Response_New_Token.token_type,cookie_Config)
                            Cookies.set('expires_in',Response_New_Token.expires_in,cookie_Config)
                            Cookies.set('scope',Response_New_Token.scope,cookie_Config)
                            const [Response_2, Data_2] = await GetUserData(Response_New_Token.access_token)
                            if (Response_2 == true){
                                localStorage.setItem('user',JSON.stringify(Data_2))
                                setProfile(Data_2.picture);
                                setUsername(Data_2.preferred_username);
                            }

                        }
                    }
                }else{
                    localStorage.setItem('user',JSON.stringify(Data))
                    setProfile(Data.picture);
                    setUsername(Data.preferred_username);
                }
            }
        };

        fetchUserData();
    }, []);
    return (
        <div id="Sidebar" className="w-56 h-full fixed top-0 left-0 bg-[#FEFAF6] pt-2 flex flex-col">
            <Link to="/profile" className="w-full flex justify-center ">
                <button className="bg-white h-14 w-[90%] flex items-center justify-center text-center mt-3">
                    <img id="Profile" src={`${Profile}`}  className="w-11 h-11 rounded-full bg-white border-2 ml-2"/>
                    <h1 className="text-left flex-grow ml-2 font-bold">{username}</h1>
                </button>
            </Link>
            <Link to="/"><button className={` mt-10 w-[80%] mx-auto rounded-xl flex text-center items-center justify-center font-semibold text-lg p-2 ${CheckPage('/')}`}><MdSpaceDashboard className="w-7 h-7 mr-2"/>Dashboard</button></Link>
            <Link to="/Server"><button className={` mt-4 w-[80%] mx-auto rounded-2xl flex text-center items-center justify-center font-semibold text-lg p-2 ${CheckPage('/Server')}`}><FaServer className="w-6 h-6 mr-2"/>Server</button></Link>
            <Link to="/Code"><button className={` mt-4 w-[80%] mx-auto rounded-2xl flex text-center items-center justify-center font-semibold text-lg p-2 ${CheckPage('/Code')}`}><IoMdAddCircle className="w-7 h-7 mr-2"/>Code</button></Link>
            <Link to="/Message"><button className={` mt-4 w-[80%] mx-auto rounded-2xl flex text-center items-center justify-center font-semibold text-lg p-2 ${CheckPage('/Message')}`}><FaMessage className="w-6 h-6 mr-2"/>Message</button></Link>
        </div>
    )
}