import Bar from '../template/sidebar'
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
export default function Home(){
    const [Visit,SetVisit] = useState(0)
    const [Playing,SetPlaying] = useState(0)
    const [Favorite,SetFavorite] = useState(0)

    useEffect(() => {
        async function GetData(){
            const GetGameInfo = await fetch(`https://api.yeke-hub.com/gameinfo?UNIVERSE_ID=${process.env.REACT_APP_UNIVERSE_ID}`,{
                method: 'GET',
                headers: {
                    'accept': 'application/json'
                }
            })
            if (GetGameInfo.ok){
                const GameInfo = await GetGameInfo.json()
                SetVisit(GameInfo.visits)
                SetPlaying(GameInfo.playing)
                SetFavorite(GameInfo.favoritedCount)
            }
        }
        GetData()
    },[])
    return (
        <div className="flex w-screen h-screen">
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <Bar/>
            <div id="Main" className="flex-1 flex flex-col items-center bg-gray-200 w-full min-h-screen ml-56 overflow-x-hidden overflow-y-auto">
                <h1 className='font-bold text-3xl ml-4 mt-4 mb-16 mr-auto'>Dashboard</h1>
                <div id='Stats' className='grid grid-cols-3 grid-flow-col gap-2  w-[90%] h-36'>
                    <div className='h-full bg-gray-100 rounded-lg'>
                        <h1 id='Visit' className='font-bold text-xl w-full text-center mb-5 mt-3'>Visit</h1>
                        <h1 className='font-semibold text-xl w-full text-center'>{Visit}</h1>
                    </div>
                    <div className='h-full bg-gray-100 rounded-lg'>
                        <h1 id='Playing' className='font-bold text-xl w-full text-center mb-5 mt-3'>Playing</h1>
                        <h1 className='font-semibold text-xl w-full text-center'>{Playing}</h1>
                    </div>
                    <div className='h-full bg-gray-100 rounded-lg'>
                        <h1 id='Favorite' className='font-bold text-xl w-full text-center mb-5 mt-3'>Favorite</h1>
                        <h1 className='font-semibold text-xl w-full text-center'>{Favorite}</h1>
                    </div>
                </div>
                <div id='Log' className='bg-gray-100 w-[95%] h-56 mt-10 rounded-lg'>
                    <div className='w-full h-16 border-b-2 flex flex-col item-center'><h1 className='h-full w-fit mt-3 ml-5 text-center font-bold text-2xl'>Log</h1></div>
                    <table className='table-auto w-full mt-8 '>
                        <thead>
                            <tr className='border-b-2'>
                                <th className='w-[25%]'>User</th>
                                <th>Message</th>
                                <th className='w-[10%]'>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='flex items-center justify-center font-semibold border-r-2'><div className="w-11 h-11 rounded-full bg-white border-2 mr-auto"></div><h1 className='font-semibold flex-grow pl-2'>ye._.ke</h1></td>
                                <td className='pl-4 font-semibold border-r-2'>Set New Code</td>
                                <td className='pl-1 font-semibold '>12/5/2024</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='w-full h-56'></div>
            </div>
        </div>
    )
}