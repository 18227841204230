import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Login from './Page/Login';
import Home from './Page/Home';
import Message from './Page/Message';
import Server from './Page/Server';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Code from './Page/Code';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route index path='/' tit element={<Home/>}/>
      <Route path='/Code' element={<Code/>}/>
      <Route path='/Login' element={<Login />}/>
      <Route path='/Server' element={<Server/>}/>
      <Route path='/Message' element={<Message/>}/>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
